import * as React from "react"

function SvgDeadPixelRepair(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 934.389 558.23"
      {...props}
    >
      <path fill="#6c63ff" d="M33.986 35.186h73.985v49.323H33.986z" />
      <path fill="#e6e6e6" d="M107.354 35.186h73.985v49.323h-73.985z" />
      <path
        fill="#6c63ff"
        opacity={0.8}
        d="M180.722 35.186h73.985v49.323h-73.985z"
      />
      <path fill="#6c63ff" d="M254.091 35.186h73.985v49.323h-73.985z" />
      <path
        fill="#6c63ff"
        opacity={0.8}
        d="M327.459 35.186h73.985v49.323h-73.985z"
      />
      <path
        fill="#e6e6e6"
        d="M400.827 35.186h73.985v49.323h-73.985zM547.563 35.186h73.985v49.323h-73.985z"
      />
      <path
        fill="#6c63ff"
        opacity={0.8}
        d="M33.986 82.043h73.985v49.323H33.986z"
      />
      <path fill="#6c63ff" d="M107.354 82.043h73.985v49.323h-73.985z" />
      <path
        fill="#6c63ff"
        opacity={0.8}
        d="M254.091 82.043h73.985v49.323h-73.985z"
      />
      <path fill="#6c63ff" d="M327.459 82.043h73.985v49.323h-73.985z" />
      <path
        fill="#6c63ff"
        opacity={0.8}
        d="M400.827 82.043h73.985v49.323h-73.985z"
      />
      <path fill="#6c63ff" d="M547.563 82.043h73.985v49.323h-73.985z" />
      <path fill="#e6e6e6" d="M33.986 128.9h73.985v49.323H33.986z" />
      <path
        fill="#6c63ff"
        opacity={0.8}
        d="M107.354 128.9h73.985v49.323h-73.985z"
      />
      <path fill="#e6e6e6" d="M180.722 128.9h73.985v49.323h-73.985z" />
      <path fill="#6c63ff" d="M254.091 128.9h73.985v49.323h-73.985z" />
      <path
        fill="#6c63ff"
        opacity={0.8}
        d="M327.459 128.9h73.985v49.323h-73.985z"
      />
      <path fill="#e6e6e6" d="M400.827 128.9h73.985v49.323h-73.985z" />
      <path
        fill="#6c63ff"
        d="M474.195 128.9h73.985v49.323h-73.985zM33.986 175.757h73.985v49.323H33.986z"
      />
      <path fill="#e6e6e6" d="M107.354 175.757h73.985v49.323h-73.985z" />
      <path
        fill="#6c63ff"
        opacity={0.8}
        d="M400.827 175.757h73.985v49.323h-73.985zM33.986 222.614h73.985v49.323H33.986z"
      />
      <path fill="#6c63ff" d="M107.354 222.614h73.985v49.323h-73.985z" />
      <path fill="#e6e6e6" d="M180.722 222.614h73.985v49.323h-73.985z" />
      <path
        fill="#6c63ff"
        opacity={0.8}
        d="M254.091 222.614h73.985v49.323h-73.985z"
      />
      <path
        fill="#6c63ff"
        opacity={0.8}
        d="M327.459 222.614h73.985v49.323h-73.985z"
      />
      <path fill="#6c63ff" d="M33.986 269.471h73.985v49.323H33.986z" />
      <path fill="#e6e6e6" d="M107.354 269.471h73.985v49.323h-73.985z" />
      <path
        fill="#6c63ff"
        opacity={0.8}
        d="M180.722 269.471h73.985v49.323h-73.985z"
      />
      <path fill="#6c63ff" d="M254.091 269.471h73.985v49.323h-73.985z" />
      <path fill="#e6e6e6" d="M33.986 317.927h73.985v49.323H33.986z" />
      <path
        fill="#6c63ff"
        opacity={0.8}
        d="M107.354 317.927h73.985v49.323h-73.985z"
      />
      <path fill="#e6e6e6" d="M180.722 317.927h73.985v49.323h-73.985z" />
      <path
        fill="#6c63ff"
        d="M180.722 82.043h73.985v49.323h-73.985zM474.195 35.186h73.985v49.323h-73.985zM327.459 175.757h73.985v49.323h-73.985z"
      />
      <path fill="#e6e6e6" d="M254.091 175.757h73.985v49.323h-73.985z" />
      <path
        fill="#6c63ff"
        opacity={0.8}
        d="M180.722 175.757h73.985v49.323h-73.985z"
      />
      <circle cx={11.195} cy={59.975} r={11.195} fill="#3f3d56" />
      <circle cx={70.978} cy={11.195} r={11.195} fill="#e6e6e6" />
      <circle cx={144.347} cy={11.195} r={11.195} fill="#e6e6e6" />
      <circle cx={217.715} cy={11.195} r={11.195} fill="#e6e6e6" />
      <circle cx={291.083} cy={11.195} r={11.195} fill="#e6e6e6" />
      <circle cx={364.451} cy={11.195} r={11.195} fill="#e6e6e6" />
      <circle cx={437.819} cy={11.195} r={11.195} fill="#e6e6e6" />
      <circle cx={511.187} cy={11.195} r={11.195} fill="#e6e6e6" />
      <circle cx={584.556} cy={11.195} r={11.195} fill="#e6e6e6" />
      <circle cx={657.924} cy={11.195} r={11.195} fill="#e6e6e6" />
      <circle cx={11.195} cy={106.704} r={11.195} fill="#3f3d56" />
      <circle cx={11.195} cy={153.561} r={11.195} fill="#3f3d56" />
      <circle cx={11.195} cy={200.418} r={11.195} fill="#3f3d56" />
      <circle cx={11.195} cy={247.275} r={11.195} fill="#3f3d56" />
      <circle cx={11.195} cy={294.132} r={11.195} fill="#3f3d56" />
      <circle cx={11.195} cy={342.588} r={11.195} fill="#3f3d56" />
      <path fill="#e6e6e6" d="M474.195 82.043h73.985v49.323h-73.985z" />
      <path
        fill="#6c63ff"
        opacity={0.8}
        d="M620.931 35.186h73.985v49.323h-73.985z"
      />
      <path
        fill="#3f3d56"
        d="M934.007 543.231H676.269v-2.106h258.12l-.382 2.106z"
      />
      <path
        fill="#ffb8b8"
        d="M763 538l13.5-1.5v-24l-14.5.5 1 25zM833 538l-14.5-1.5L821 513l16.5-.5L833 538z"
      />
      <path
        d="M756.5 516.5l21.67-.606c13.578-70.971 26.819-136.614 48.33-191.394l-9 191h23l35-214-80-8c-26.794 70.803-38.953 145.351-39 223zM750.45 557.433a12.164 12.164 0 0013.615-3.51l5.435-6.423 10-5-1.595-16.743a1.433 1.433 0 00-2.646-.615l-5.76 9.358c-2.123-7.654-5.792-11.631-11.54-10.664a11.945 11.945 0 00-8.388 6.246l-5.502 10.217a12.164 12.164 0 006.38 17.134zM844.55 557.433a12.164 12.164 0 01-13.615-3.51L825.5 547.5l-10-5 1.595-16.743a1.433 1.433 0 012.646-.615l5.759 9.358c2.124-7.654 5.793-11.631 11.54-10.664a11.945 11.945 0 018.389 6.246l5.501 10.217a12.164 12.164 0 01-6.38 17.134z"
        fill="#2f2e41"
      />
      <circle cx={836} cy={101} r={24} fill="#ffb8b8" />
      <path fill="#ffb8b8" d="M850.5 145.5h-30v-32h30z" />
      <path
        d="M799 294c25.128 5.889 51.7 14.414 69 6l-.5-155.5a31.222 31.222 0 01-17-15l-12 10-18-11c-6.257 7.56-14.663 15.514-24.5 18.5z"
        fill="#e6e6e6"
      />
      <path
        d="M839.5 267.5c-4.305 42.615 15.789 39.8 42 39-10.902-54.361-10.538-105.856 3-154-13.677-6.558-25.69-14.14-34-24 .408 1.079-.861.426-.5 1.5 8.487 25.24 5.641 50.846-10.5 73.5z"
        fill="#2f2e41"
      />
      <path
        d="M888 310l-6.907 13.815a10.726 10.726 0 007.182 15.248 10.726 10.726 0 0013.133-10.116L902 310v-15h-14z"
        fill="#ffb8b8"
      />
      <path
        d="M841.5 268.5c-.985 32.546-4.314 37.455-52 36 10.902-54.361 10.538-105.856-3-154 13.677-6.558 25.69-14.14 34-24-.565 1.492.927 2.005.5 3.5-7.128 24.985 4.622 51.215 20.5 73.5z"
        fill="#2f2e41"
      />
      <path
        d="M782 306l6.907 13.815a10.726 10.726 0 01-7.182 15.248 10.726 10.726 0 01-13.133-10.116L768 306v-15h14z"
        fill="#ffb8b8"
      />
      <path
        d="M837.32 60.03a25.336 25.336 0 0111.575 3.407c.812.472.437 2.175 1.186 2.73.945.7 2.995.275 3.83 1.094a25.39 25.39 0 017.551 20.457l-1.331 13.236-3.14-3.434A32.737 32.737 0 00835.157 86.8q-.534-.036-1.07-.057l2.416-4.228-4.199 4.2a40.887 40.887 0 00-5.72.465l3.212-5.623-6.165 6.165a16.484 16.484 0 00-11.518 9.394l-.685 1.518-.767-12.628A25.453 25.453 0 01837.32 60.03z"
        fill="#2f2e41"
      />
      <ellipse cx={859.499} cy={100.002} rx={1.916} ry={4.551} fill="#ffb8b8" />
      <ellipse cx={811.593} cy={98.565} rx={1.916} ry={4.551} fill="#ffb8b8" />
      <path
        d="M785 300l-23.5 1.5 12-131.877a22.7 22.7 0 0116.464-21.827l4.536-1.296c6.52 54.19-.782 106.073-9.5 153.5zM886 303l18 1-6.5-132.377a22.7 22.7 0 00-16.464-21.827L876.5 148.5c-6.52 54.19.782 107.073 9.5 154.5z"
        fill="#2f2e41"
      />
    </svg>
  )
}

export default SvgDeadPixelRepair
