import React, { memo } from "react"

const HeroImage = props => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      
      viewBox="0 0 944 587.805"
      {...props}
    >
      <path fill="#3f3d56" d="M76 325.689h186v174H76zM230 557.689h420v2H230z" />
      <path
        d="M338 560.189H0v-295h338zm-336-2h334v-291H2zM809 426.689c-74.44 0-135-60.56-135-135s60.56-135 135-135 135 60.56 135 135-60.56 135-135 135zm0-268c-73.337 0-133 59.663-133 133s59.663 133 133 133 133-59.663 133-133-59.663-133-133-133z"
        fill="#3f3d56"
      />
      <circle cx={805} cy={291.689} r={65} fill="#3f3d56" />
      <path
        fill="#ff6584"
        d="M635.001 147.065L603.418 165.3l-18.235-31.583 31.583-18.235z"
      />
      <path
        fill="#3f3d56"
        d="M170.001 164.065L138.418 182.3l-18.235-31.583 31.583-18.235z"
      />
      <path
        fill="#d0cde1"
        d="M572.001 492.065L540.418 510.3l-18.235-31.583 31.583-18.235z"
      />
      <path fill="#6c63ff" d="M152 395.689h34v34h-34z" />
      <circle cx={805} cy={291.689} r={16} fill="#6c63ff" />
      <path
        fill="#2f2e41"
        d="M499.755 112.048l-69.288 1.721-9.327-82.619h78.615v80.898zM426.41 219.15l-25.29 175.354s-21.92 62.386-23.606 92.736l-6.745 43.838 30.35 6.744 32.036-123.085 43.839-111.282-5.059 128.143-10.116 104.538 30.35 5.059 18.547-133.202 20.233-97.794s15.175-84.305-8.43-92.735z"
      />
      <path
        d="M399.09 520.317l-3.375 41.039s36.117 20.88 24.528 24.928-27.45-1.16-30.694-2.08c-3.426-.971-9.822-2.52-14.662-3.667a10.753 10.753 0 01-8.324-9.685l-.009-.15c-.292-5.05 11.102-48.935 11.102-48.935zM486.796 521.343l2.843 39.242s41.128 15.28 30.526 21.467-27.165 4.107-30.526 3.824c-6.195-.522-21.614-1.042-21.614-1.042s-2.85 2.468-4.101-2.433 1.669-48.683 1.669-48.683z"
        fill="#2f2e41"
      />
      <circle cx={461.819} cy={45.482} r={32.036} fill="#ffb8b8" />
      <path
        fill="#ffb8b8"
        d="M443.272 64.029l-6.745 37.094 28.664 13.489 6.744-47.211-28.663-3.372z"
      />
      <path
        d="M461.819 104.495l-3.373-13.489-20.233-10.116-6.744 8.43-21.92 6.745 25.292 134.888s85.991 5.058 87.677-8.43-16.86-50.584-16.86-50.584 5.057-18.547 3.371-28.663l-6.744-40.467-26.978-8.43-6.16-7.454-5.642 9.14z"
        fill="#d0cde1"
      />
      <path
        d="M424.508 43.2c23.971-9.608 47.603-11.658 70.62 0V23.833a12.613 12.613 0 00-12.613-12.613h-48.043a9.964 9.964 0 00-9.964 9.964z"
        fill="#2f2e41"
      />
      <path
        fill="#6c63ff"
        d="M565.524 321.708l-67.748 39.114-39.114-67.747 67.748-39.114z"
      />
      <path
        d="M439.9 239.383l35.407 25.292s15.175 38.78 26.978 32.035-13.489-40.466-13.489-40.466l-28.663-28.664zM541.065 227.58l3.372 20.234s-8.43 42.152-18.547 33.722 3.373-35.408 3.373-35.408l-1.687-16.861z"
        fill="#ffb8b8"
      />
      <path
        d="M414.608 99.437l-5.058-3.372s-10.117 1.686-13.49 15.175 0 74.188 8.431 85.99 32.036 47.211 32.036 47.211l32.036-15.174-33.722-38.78-8.43-48.898zM488.796 106.181l13.489-3.372s8.43 3.372 11.803 15.175 21.919 70.816 20.233 75.874 15.175 38.78 15.175 38.78l-23.606 1.687-13.488-35.408-13.49-35.408z"
        fill="#d0cde1"
      />
      <path
        d="M406.294 75.905c6.756-3.71 9.9-12.037 10.125-19.742.224-7.704-1.645-15.33-1.791-23.036a17.805 17.805 0 01.4-4.697 18.069 18.069 0 012.583-5.348C426.397 9.62 441.669.15 457.755 0a17.83 17.83 0 016.793 1.249c1.663.666 2.207.584 2.526 2.626a16.999 16.999 0 0111.666.371 39.47 39.47 0 0110.202 6.076 46.015 46.015 0 015.158 4.466c8.501 8.807 10.459 22.38 7.996 34.37-.8 3.899 3.041 7.662 6.22 10.056s6.85 4.593 8.41 8.254c1.502 3.521.677 7.548.98 11.365a19.437 19.437 0 0012.4 16.33 5.982 5.982 0 01-5.772 2.03 14.205 14.205 0 01-6.014-2.863 35.775 35.775 0 01-13.337-19.788c-.934 4.246-.572 8.626-.537 12.974a36.24 36.24 0 002.627 14.171 20.75 20.75 0 009.554 10.52c-4.17 2.041-8.765.562-12.298-2.45s-5.62-7.384-7.078-11.792a58.772 58.772 0 01-2.832-22.306 31.85 31.85 0 00-3.996 21.714 33.197 33.197 0 0011.26 19.544 64.676 64.676 0 01-29.603-7.455 16.754 16.754 0 01-6.071-4.676c-2.938-4.076-2.243-9.636-1.42-14.592.653-3.92-4.245-9.586-1.44-12.4a4.65 4.65 0 013.117-1.34c8.544-.822 16.454-5.113 21.014-12.384 3.195-5.096 5.311-10.915 5.311-16.357a29.764 29.764 0 10-58.192 8.832 59.726 59.726 0 012.167 10.087 79.252 79.252 0 002.416 11.734c2.122 7.389 5.717 14.928 12.369 18.782-2.236 1.224-4.823 1.279-7.355 1.558-8.347.92-17.126.717-24.68-2.95-7.556-3.665-13.56-11.472-13.022-19.852z"
        fill="#2f2e41"
      />
      <ellipse cx={431.967} cy={49.538} rx={3.533} ry={5.653} fill="#ffb8b8" />
    </svg>
  )
}

export default memo(HeroImage)
