import React from "react"

function SvgDevices(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 920.109 411.952"
      {...props}
    >
      <path
        d="M436.527 410h195.59c7.722 0 7.734-12 0-12h-195.59c-7.722 0-7.735 12 0 12z"
        fill="#e6e6e6"
      />
      <path
        d="M383.72 149.231H188.786a7.777 7.777 0 00-7.767 7.767v38.726c9.724.228 16.035 8.252 17.565 17.993v35.106h.738a.444.444 0 01.443.443v18.68a.444.444 0 01-.443.444h-.738V381.3c0 2.48-.025 7.006-.025 7.006h185.16a7.777 7.777 0 007.768-7.767v-223.54a7.777 7.777 0 00-7.767-7.768z"
        fill="#6c63ff"
      />
      <path
        d="M745.9 21.81v211.9H404.969v-93.295a15.993 15.993 0 00-15.978-15.977h-63.939V21.81z"
        fill="#6c63ff"
      />
      <path
        d="M766.95 263.583h-361.6v-1.476h360.123V12.552a11.088 11.088 0 00-11.075-11.075h-442.26a11.088 11.088 0 00-11.074 11.075v110.93h-1.477V12.551A12.566 12.566 0 01312.14 0h442.259a12.566 12.566 0 0112.551 12.552z"
        fill="#3f3d56"
      />
      <path
        d="M732.687 308.621H405.35v-1.476h327.337a32.823 32.823 0 0032.786-32.786v-10.776H405.35v-1.476h361.6v12.252a34.301 34.301 0 01-34.263 34.262z"
        fill="#3f3d56"
      />
      <path
        d="M635.54 380.978c-18.249-17.522-30.253-40.385-34.112-72.357H466.94c-1.368 29.912-13.296 52.345-34.813 72.278l-2.273.08c22.2-20.12 34.427-42.717 35.64-73.125l.03-.71H602.74l.077.654c3.79 32.513 16.045 55.523 34.742 73.061zM388.634 411.524H181.461v-1.476h207.173a15.995 15.995 0 0015.977-15.977V140.754a15.995 15.995 0 00-15.977-15.976H181.461a15.995 15.995 0 00-15.977 15.976v54.668h-1.476v-54.668a17.473 17.473 0 0117.453-17.453h207.173a17.473 17.473 0 0117.454 17.453v253.317a17.473 17.473 0 01-17.454 17.453z"
        fill="#3f3d56"
      />
      <path
        d="M285.048 141.983a5.007 5.007 0 115.006-5.007 5.012 5.012 0 01-5.006 5.007zm0-8.856a3.85 3.85 0 103.85 3.849 3.854 3.854 0 00-3.85-3.85zM285.048 405.865a6.388 6.388 0 116.388-6.39 6.396 6.396 0 01-6.388 6.39zm0-11.3a4.912 4.912 0 104.912 4.91 4.917 4.917 0 00-4.912-4.91zM179.38 411.952H110.8a19.495 19.495 0 01-19.473-19.473V214.895a19.495 19.495 0 0119.474-19.473h68.58a19.495 19.495 0 0119.473 19.473v31.78a1.923 1.923 0 011.182 1.772v20.678a1.923 1.923 0 01-1.182 1.772V392.48a19.495 19.495 0 01-19.473 19.473zM110.8 196.898a18.017 18.017 0 00-17.996 17.997v177.584a18.017 18.017 0 0017.997 17.996h68.58a18.017 18.017 0 0017.996-17.996V269.568h.739a.444.444 0 00.443-.443v-20.678a.444.444 0 00-.443-.444h-.739v-33.108a18.017 18.017 0 00-17.996-17.997z"
        fill="#3f3d56"
      />
      <path
        d="M180.137 201.034h-8.952a6.647 6.647 0 01-6.155 9.158h-39.288a6.647 6.647 0 01-6.155-9.158h-8.36a13.991 13.991 0 00-13.992 13.991V392.35a13.991 13.991 0 0013.991 13.99h68.91a13.991 13.991 0 0013.992-13.99V215.025a13.991 13.991 0 00-13.991-13.99z"
        fill="#6c63ff"
      />
      <path
        d="M919.23 411.663H.88a.88.88 0 110-1.758h918.35a.88.88 0 110 1.758z"
        fill="#3f3d56"
      />
      <path
        d="M635.995 411.25H431.478a15.505 15.505 0 110-31.01h204.517a15.505 15.505 0 010 31.01zm-204.517-29.534a14.028 14.028 0 000 28.056h204.517a14.028 14.028 0 000-28.056z"
        fill="#3f3d56"
      />
      <path
        fill="#ffb8b8"
        d="M784.495 293.196l5.728 11.976 48.917-16.394-8.454-17.675-46.191 22.093z"
      />
      <path
        d="M790.15 333.586l-18.002-37.637 14.541-6.955 11.579 24.207a14.887 14.887 0 01-7.007 19.853l-1.112.532z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb8b8"
        d="M772.964 400.141l13.267-.448.558-51.644-15.552.918 1.727 51.174z"
      />
      <path
        d="M811.974 411.857h-41.721v-16.12h26.834a14.887 14.887 0 0114.887 14.888v1.232zM774.055 392.383a4.974 4.974 0 01-4.83-3.786c-7.268-29.201-34.311-176.333-10.397-205.23l.208-.252.317-.08c1.679-.425 33.325-10.223 51.894 5.07l.217.18 10.334 29.914 27.859 41.355a20.403 20.403 0 01-6.033 28.653l-36.582 23.086a5.009 5.009 0 01-5.753-.405 5.06 5.06 0 01-1.5-2.092l-7.689-19.22a5.03 5.03 0 012.407-6.329l17.14-8.57a3.674 3.674 0 001.83-2.504 2.959 2.959 0 00-1.03-2.543l-19.895-17.151a3 3 0 00-4.957 2.385l4.932 130.203a5.002 5.002 0 01-4.217 5.128l-13.456 2.124a5.049 5.049 0 01-.799.064z"
        fill="#2f2e41"
      />
      <path
        d="M808.376 193.413q-.169 0-.34-.013l-49.615-3.675a4.493 4.493 0 01-3.914-5.97 47.092 47.092 0 001.076-27.319c-3.232-12.742-3.308-36.433-3.066-49.42a8.753 8.753 0 018.28-8.523l.001-.017 1 .012c8.083.113 22.916.69 35.053 3.215a18.39 18.39 0 0114.66 17.7c.274 12.058.315 28.38-1.053 37.657-1.155 7.827.978 22.858 2.351 31.122a4.498 4.498 0 01-4.433 5.23z"
        fill="#3f3d56"
      />
      <path
        d="M826.089 240.71a10.025 10.025 0 01-3.81-14.892L799.362 127h17.324l20.225 96.87a10.08 10.08 0 01-10.823 16.84zM717.558 137.705l27.436-25.953 10.336 14.47-25.84 22.74 33.887 26.91c.245-.017.489-.038.738-.038a10.853 10.853 0 11-10.745 12.296l-.107.107-35.27-30.566a13.47 13.47 0 01-.435-19.966z"
        fill="#ffb8b8"
      />
      <path
        d="M801.188 133.332a4.47 4.47 0 01-4.052-2.554 52.813 52.813 0 01-4.468-14.57c-1.19-8.244 4.542-13.148 10.372-14.217 5.67-1.04 12.578 1.454 14.343 9.383a20.451 20.451 0 01.342 2.032c.707 6.01 1.373 10.686 1.978 13.896a4.495 4.495 0 01-4.199 5.32l-14.086.704q-.115.006-.23.006z"
        fill="#3f3d56"
      />
      <circle cx={781.544} cy={59.981} r={30} fill="#2f2e41" />
      <circle cx={781.725} cy={67.769} r={24.561} fill="#ffb8b8" />
      <path
        d="M738.132 121.379a4.47 4.47 0 01-1.262-4.62 52.813 52.813 0 016.55-13.761c4.693-6.882 12.233-6.624 17.252-3.473 4.883 3.065 8.29 9.57 4.235 16.608a20.453 20.453 0 01-1.12 1.73c-3.54 4.908-6.209 8.804-7.931 11.58a4.495 4.495 0 01-6.69 1.085l-10.86-8.998q-.09-.074-.174-.151z"
        fill="#3f3d56"
      />
      <path
        d="M775.717 33.189c-6.037-3.602-7.344-12.572-3.748-18.612s10.865-9.19 17.892-9.398c10.037-.297 19.73 4.7 26.756 11.875s11.662 16.357 15.194 25.756 6.069 19.148 9.55 28.567c1.787 4.833 3.986 9.796 8.073 12.934s10.582 3.705 14.076-.083c-.013 6.061-4.66 11.571-10.423 13.448s-12.283.46-17.336-2.89c-8.764-5.808-12.996-16.41-15.415-26.643s-3.61-20.955-8.474-30.278c-2.015-3.862-4.776-7.532-8.661-9.503s-9.062-1.841-12.187 1.194c-1.448 1.406-2.385 3.323-4.028 4.495a8.047 8.047 0 01-7.036.732 23.801 23.801 0 01-6.362-3.59"
        fill="#2f2e41"
      />
      <path
        d="M756.075 51.174a29.383 29.383 0 0042.121 5.257c2.385-1.94 4.6-4.614 4.45-7.685-.153-3.145-2.724-5.615-5.349-7.355a34.484 34.484 0 00-23.404-5.378c-4.675.629-9.364 2.333-12.674 5.693s-4.96 8.585-3.253 12.982"
        fill="#2f2e41"
      />
    </svg>
  )
}

export default SvgDevices
