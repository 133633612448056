import React from "react"

function SvgStuckPixelRepair(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 802 462.415"
      {...props}
    >
      <circle cx={775.091} cy={21.98} r={6.467} fill="#f2f2f2" />
      <circle cx={749.317} cy={21.98} r={6.467} fill="#f2f2f2" />
      <circle cx={723.544} cy={21.98} r={6.467} fill="#f2f2f2" />
      <circle cx={697.771} cy={21.98} r={6.467} fill="#f2f2f2" />
      <circle cx={775.091} cy={48.98} r={6.467} fill="#f2f2f2" />
      <circle cx={749.317} cy={48.98} r={6.467} fill="#f2f2f2" />
      <circle cx={723.544} cy={48.98} r={6.467} fill="#f2f2f2" />
      <circle cx={697.771} cy={48.98} r={6.467} fill="#f2f2f2" />
      <circle cx={775.091} cy={75.98} r={6.467} fill="#f2f2f2" />
      <circle cx={749.317} cy={75.98} r={6.467} fill="#f2f2f2" />
      <circle cx={723.544} cy={75.98} r={6.467} fill="#f2f2f2" />
      <circle cx={697.771} cy={75.98} r={6.467} fill="#f2f2f2" />
      <circle cx={775.091} cy={102.98} r={6.467} fill="#f2f2f2" />
      <circle cx={749.317} cy={102.98} r={6.467} fill="#f2f2f2" />
      <circle cx={723.544} cy={102.98} r={6.467} fill="#f2f2f2" />
      <circle cx={697.771} cy={102.98} r={6.467} fill="#f2f2f2" />
      <circle cx={775.091} cy={129.98} r={6.467} fill="#f2f2f2" />
      <circle cx={749.317} cy={129.98} r={6.467} fill="#f2f2f2" />
      <circle cx={723.544} cy={129.98} r={6.467} fill="#f2f2f2" />
      <circle cx={697.771} cy={129.98} r={6.467} fill="#f2f2f2" />
      <circle cx={775.091} cy={156.98} r={6.467} fill="#f2f2f2" />
      <circle cx={749.317} cy={156.98} r={6.467} fill="#f2f2f2" />
      <circle cx={723.544} cy={156.98} r={6.467} fill="#f2f2f2" />
      <circle cx={697.771} cy={156.98} r={6.467} fill="#f2f2f2" />
      <path
        d="M764.083 445.96c4.668-10.083 9.333-20.316 11.36-31.241s1.175-22.783-4.628-32.26-17.228-15.902-28.131-13.76c-8.956 1.76-16.146 8.813-20.357 16.91s-5.9 17.23-7.537 26.208c-.53-10.2-1.09-20.564-4.478-30.2s-10.06-18.614-19.57-22.342-21.879-.797-26.89 8.103c-7.061 12.538 2.301 30.125-5.818 42.006-1.392-11.917-13.854-21.333-25.698-19.416s-20.702 14.78-18.265 26.53c1.45 6.99 6.22 12.935 11.921 17.231s12.335 9.274 18.895 12.09z"
        fill="#f2f2f2"
      />
      <path
        d="M620.29 398.387c9.408 3.289 18.903 6.615 27.492 11.756 7.698 4.608 14.553 10.812 18.882 18.757a33.356 33.356 0 014.126 13.858c.063 1.018 1.655 1.025 1.592 0-.557-9.06-4.973-17.353-11.087-23.91-6.704-7.19-15.392-12.1-24.361-15.91-5.319-2.26-10.77-4.18-16.221-6.086-.97-.338-1.386 1.199-.423 1.535zM674.557 360.854a143.382 143.382 0 0113.791 30.616 145.117 145.117 0 016.361 32.968 143.307 143.307 0 01.152 18.837c-.06 1.023 1.533 1.02 1.591 0a145.195 145.195 0 00-2.04-33.82 146.927 146.927 0 00-9.769-32.44 143.252 143.252 0 00-8.712-16.964.796.796 0 00-1.374.803zM752.458 369.997a232.043 232.043 0 00-17.117 57.578q-1.342 8.365-2.078 16.812c-.089 1.02 1.503 1.014 1.592 0a231.209 231.209 0 0112.738-58.025q2.834-7.892 6.239-15.562c.412-.928-.959-1.738-1.374-.803z"
        fill="#fff"
      />
      <path
        d="M801 445.694H1a1 1 0 010-2h800a1 1 0 010 2zM414.263 190.543H275.534a7.53 7.53 0 01-7.521-7.522V7.521A7.53 7.53 0 01275.534 0h138.729a7.53 7.53 0 017.521 7.521v175.5a7.53 7.53 0 01-7.521 7.522zM275.534 1.67a5.857 5.857 0 00-5.85 5.85v175.5a5.857 5.857 0 005.85 5.85h138.729a5.857 5.857 0 005.85-5.85V7.521a5.857 5.857 0 00-5.85-5.85z"
        fill="#3f3d56"
      />
      <path
        d="M333.358 84.358h-30.537a3.765 3.765 0 01-3.76-3.76V44.562a3.765 3.765 0 013.76-3.76h30.537a3.765 3.765 0 013.76 3.76v36.036a3.765 3.765 0 01-3.76 3.76z"
        fill="#6c63ff"
      />
      <path
        d="M392.694 101.073h-30.537a3.765 3.765 0 01-3.76-3.761V61.276a3.765 3.765 0 013.76-3.76h30.537a3.765 3.765 0 013.76 3.76v36.036a3.765 3.765 0 01-3.76 3.76z"
        fill="#ccc"
      />
      <path
        d="M345.058 152.051h-30.537a3.765 3.765 0 01-3.76-3.76v-36.036a3.765 3.765 0 013.76-3.76h30.537a3.765 3.765 0 013.76 3.76v36.036a3.765 3.765 0 01-3.76 3.76z"
        fill="#ff6584"
      />
      <circle cx={115.931} cy={11.82} r={6.467} fill="#f2f2f2" />
      <circle cx={115.931} cy={37.594} r={6.467} fill="#f2f2f2" />
      <circle cx={115.931} cy={63.367} r={6.467} fill="#f2f2f2" />
      <circle cx={115.931} cy={89.14} r={6.467} fill="#f2f2f2" />
      <circle cx={142.931} cy={11.82} r={6.467} fill="#f2f2f2" />
      <circle cx={142.931} cy={37.594} r={6.467} fill="#f2f2f2" />
      <circle cx={142.931} cy={63.367} r={6.467} fill="#f2f2f2" />
      <circle cx={142.931} cy={89.14} r={6.467} fill="#f2f2f2" />
      <circle cx={169.931} cy={11.82} r={6.467} fill="#f2f2f2" />
      <circle cx={169.931} cy={37.594} r={6.467} fill="#f2f2f2" />
      <circle cx={169.931} cy={63.367} r={6.467} fill="#f2f2f2" />
      <circle cx={169.931} cy={89.14} r={6.467} fill="#f2f2f2" />
      <circle cx={196.931} cy={11.82} r={6.467} fill="#f2f2f2" />
      <circle cx={196.931} cy={37.594} r={6.467} fill="#f2f2f2" />
      <circle cx={196.931} cy={63.367} r={6.467} fill="#f2f2f2" />
      <circle cx={196.931} cy={89.14} r={6.467} fill="#f2f2f2" />
      <circle cx={223.931} cy={11.82} r={6.467} fill="#f2f2f2" />
      <circle cx={223.931} cy={37.594} r={6.467} fill="#f2f2f2" />
      <circle cx={223.931} cy={63.367} r={6.467} fill="#f2f2f2" />
      <circle cx={223.931} cy={89.14} r={6.467} fill="#f2f2f2" />
      <circle cx={250.931} cy={11.82} r={6.467} fill="#f2f2f2" />
      <circle cx={250.931} cy={37.594} r={6.467} fill="#f2f2f2" />
      <circle cx={250.931} cy={63.367} r={6.467} fill="#f2f2f2" />
      <circle cx={250.931} cy={89.14} r={6.467} fill="#f2f2f2" />
      <circle cx={181.045} cy={412.421} r={42.012} fill="#2f2e41" />
      <path
        fill="#2f2e41"
        d="M144.622 450.263l20.447-10.24 5.715 11.412-20.447 10.24z"
      />
      <ellipse
        cx={343.153}
        cy={673.455}
        rx={3.989}
        ry={10.636}
        transform="rotate(-56.601 40.487 748.846)"
        fill="#2f2e41"
      />
      <path
        fill="#2f2e41"
        d="M191.305 451.436l5.715-11.412 20.448 10.24-5.715 11.411z"
      />
      <ellipse
        cx={416.936}
        cy={673.455}
        rx={10.636}
        ry={3.989}
        transform="rotate(-33.399 -47.214 895.722)"
        fill="#2f2e41"
      />
      <circle cx={178.104} cy={402.145} r={14.359} fill="#fff" />
      <path
        d="M169.696 402.492c5.123-3.902 10.914 1.89 7.012 7.012-5.123 3.902-10.914-1.89-7.012-7.012z"
        fill="#3f3d56"
      />
      <path
        d="M214.187 366.716c.631-15.553-12.773-28.727-29.941-29.425s-31.597 11.346-32.229 26.9 11.302 19.087 28.47 19.785 33.068-1.706 33.7-17.26z"
        fill="#ccc"
      />
      <ellipse
        cx={423.721}
        cy={637.274}
        rx={6.594}
        ry={21.006}
        transform="rotate(-40.645 28.842 796.536)"
        fill="#2f2e41"
      />
      <ellipse
        cx={336.486}
        cy={637.274}
        rx={21.006}
        ry={6.594}
        transform="rotate(-49.355 -1.105 744.431)"
        fill="#2f2e41"
      />
      <path
        d="M192.584 428.978a9.572 9.572 0 11-18.836 3.429l-.003-.019c-.942-5.202 3.08-7.043 8.282-7.985s9.615-.627 10.557 4.575z"
        fill="#fff"
      />
      <path
        d="M625.297 327.323h-56.01a2.501 2.501 0 01-2.5 2.5h-54.98a12.86 12.86 0 00-2.11 2 12.41 12.41 0 00-2.9 8v2a12.505 12.505 0 0012.5 12.5h106a12.51 12.51 0 0012.5-12.5v-2a12.517 12.517 0 00-12.5-12.5z"
        fill="#3f3d56"
      />
      <path fill="#3f3d56" d="M566.292 353.827h13v92h-13z" />
      <path
        d="M616.292 445.37c0 1.405-19.7.543-44 .543s-44 .862-44-.543 19.7-12.543 44-12.543 44 11.138 44 12.543z"
        fill="#3f3d56"
      />
      <path
        d="M622.847 330.203l-2.18-13.64a6.574 6.574 0 00-7.52-5.45l-22.49 3.59-38.52 6.15-12.53 2h27.18a2.501 2.501 0 012.5 2.5v1.97a2.501 2.501 0 01-2.5 2.5h-69.17a6.332 6.332 0 00-2.43 2 6.487 6.487 0 00-1.24 4.96l.62 3.88.75 4.71.81 5.06a6.56 6.56 0 007.5 5.44l17.87-2.85 51.54-8.23 44.36-7.08a6.587 6.587 0 005.45-7.51z"
        fill="#6c63ff"
      />
      <path
        d="M520.127 331.823a6.356 6.356 0 00-2.53-2h-19.12a6.321 6.321 0 00-2.54 2 6.465 6.465 0 00-1.37 3.99v76.21a6.56 6.56 0 006.55 6.55h13.83a6.56 6.56 0 006.55-6.55v-76.21a6.484 6.484 0 00-1.37-3.99zM561.747 202.023l-.13-.17-8.58-10.57a6.315 6.315 0 00-1.63-1.43 4.813 4.813 0 00-1.06-.54 6.3 6.3 0 00-1.71-.42 6.65 6.65 0 00-1.18-.02.032.032 0 00-.02.01 6.485 6.485 0 00-3.62 1.44l-17.61 14.28-22.63 18.36-33.77 27.4-2.46 2h42.73l2.47-2 8.17-6.63.58-.46 39.46-32.01.03-.02a6.457 6.457 0 001.87-2.45 6.6 6.6 0 00.52-1.97 6.501 6.501 0 00-1.43-4.8z"
        fill="#6c63ff"
      />
      <path
        d="M561.617 201.853a16.303 16.303 0 00-3.56-7.3 16.118 16.118 0 00-6.65-4.7c-.4-.15-.79-.29-1.19-.4a17.15 17.15 0 00-2.76-.58.032.032 0 00-.02.01 16.314 16.314 0 00-11.8 3.26l-30.46 23.28a6.462 6.462 0 00-2.15 4.57 6.633 6.633 0 00.55 2.97c.1.21.21.42.33.62a5.756 5.756 0 00.84 1.12l4.88 5.48 11.35 12.73.32.36.73.83a6.55 6.55 0 009.27.41l26.27-28.22a16.213 16.213 0 003.19-5.03 16.466 16.466 0 00.86-9.41z"
        fill="#2f2e41"
      />
      <circle cx={573.231} cy={115.521} r={50.122} fill="#6c63ff" />
      <path
        d="M592.027 323.303l-1.37-8.6-.81-5.05a6.563 6.563 0 00-7.51-5.44l-36.43 5.81-9.88 1.58-2 .32v10.93h32.76a2.501 2.501 0 012.5 2.5v1.97a2.501 2.501 0 01-2.5 2.5h-103.67c0 .02.01.04.01.06l.62 3.88 1.56 9.76a6.56 6.56 0 007.5 5.44l17.87-2.85 3.89-.62.75-.12 26.18-4.18 17.81-2.85 28.26-4.51.42-.07 18.59-2.96a6.568 6.568 0 005.45-7.5z"
        fill="#6c63ff"
      />
      <path
        d="M641.447 286.523c0 40.86-4.15 65.3-68.15 58.3l-.26-.03a190.03 190.03 0 01-33.73-6.45 100.174 100.174 0 01-16.64-6.52c-1.26-.64-2.47-1.31-3.66-2h47.78a2.501 2.501 0 002.5-2.5v-1.97a2.501 2.501 0 00-2.5-2.5h-32.76v-63.16a7.34 7.34 0 00-7.33-7.33h1.35c.23-.66.46-1.33.71-2 2.43-6.75 2.191-12.06 5.64-18.67 4.74-9.12 4.77-18.29 11-26 4.64-5.73-9.57-11.98-4.35-16.28 10.03-8.28 20.95-13.4 32.03-13.4 40.86 0 68.37 69.65 68.37 110.51zM574.284 134.097l.052-1.873c-3.485-.096-6.557-.315-8.865-2.002a5.758 5.758 0 01-2.23-4.24 3.291 3.291 0 011.08-2.711c1.532-1.294 3.997-.875 5.794-.051l1.55.71-2.972-21.718-1.855.254 2.527 18.474a6.612 6.612 0 00-6.254.9 5.124 5.124 0 00-1.742 4.207 7.616 7.616 0 002.997 5.688c2.966 2.168 6.82 2.276 9.918 2.362zM547.573 104.059h10.088v1.873h-10.088zM579.414 104.059h10.088v1.873h-10.088z"
        fill="#2f2e41"
      />
      <path
        d="M603.027 216.353l-3.97-2.76-7.39-5.13a6.564 6.564 0 00-9.12 1.65l-24.09 34.67-22.43 32.28-2 2.88v42.91h.18a6.531 6.531 0 004.09-2 5.832 5.832 0 00.63-.77l6.98-10.06 17.25-24.83 17.88-25.72 1.7-2.45 21.92-31.55a5.683 5.683 0 00.33-.52 5.266 5.266 0 00.27-.53 6.55 6.55 0 00-2.23-8.07z"
        fill="#6c63ff"
      />
      <path
        d="M603.097 202.633a16.089 16.089 0 00-2.22-1.22 16.359 16.359 0 00-19.98 5.16l-22.76 30.86a6.525 6.525 0 002.18 9.04l20.72 13 .26.16 1.04.65a6.573 6.573 0 009.01-2.2l16.07-31.9.27-.53.01-.01.99-1.99a16.363 16.363 0 00-5.59-21.02z"
        fill="#2f2e41"
      />
      <path
        d="M463.747 329.823v75.29a6.56 6.56 0 006.55 6.55h13.83a6.56 6.56 0 006.55-6.55v-75.29z"
        fill="#6c63ff"
      />
      <path
        d="M566.787 320.853h-30.76v-61.16a9.34 9.34 0 00-9.33-9.33h-114.07a9.342 9.342 0 00-9.34 9.33v62.8a9.342 9.342 0 009.34 9.33h154.16c.16 0 .31-.01.46-.02.14-.01.28-.04.42-.06a4.516 4.516 0 003.62-4.42v-1.97a4.5 4.5 0 00-4.5-4.5zm0 8.97h-154.16a7.343 7.343 0 01-7.34-7.33v-62.8a7.343 7.343 0 017.34-7.33h114.07a7.34 7.34 0 017.33 7.33v63.16h32.76a2.501 2.501 0 012.5 2.5v1.97a2.501 2.501 0 01-2.5 2.5z"
        fill="#3f3d56"
      />
      <circle cx={469.658} cy={291.094} r={7.69} fill="#6c63ff" />
      <path fill="#3f3d56" d="M533.997 321.913h2v9h-2z" />
      <ellipse
        cx={419.239}
        cy={476.739}
        rx={21.534}
        ry={6.76}
        transform="rotate(-69.082 160.81 511.894)"
        fill="#2f2e41"
      />
      <circle
        cx={379.432}
        cy={509.261}
        r={43.067}
        transform="rotate(-80.783 151.352 516.813)"
        fill="#2f2e41"
      />
      <path
        fill="#2f2e41"
        d="M160.806 324.269h13.084v23.442h-13.084zM186.974 324.269h13.084v23.442h-13.084z"
      />
      <ellipse
        cx={171.709}
        cy={347.983}
        rx={10.903}
        ry={4.089}
        fill="#2f2e41"
      />
      <ellipse
        cx={197.877}
        cy={347.438}
        rx={10.903}
        ry={4.089}
        fill="#2f2e41"
      />
      <circle
        cx={380.522}
        cy={498.358}
        r={14.719}
        transform="rotate(-1.683 -7165.96 7162.274)"
        fill="#fff"
      />
      <circle cx={181.522} cy={279.566} r={4.906} fill="#3f3d56" />
      <path
        d="M138.655 250.417c-3.477-15.573 7.639-31.31 24.829-35.148s33.944 5.675 37.422 21.249-7.915 21.317-25.105 25.156-33.668 4.317-37.146-11.257z"
        fill="#6c63ff"
      />
      <ellipse
        cx={330.924}
        cy={489.278}
        rx={6.76}
        ry={21.534}
        transform="rotate(-64.626 58.462 537.197)"
        fill="#2f2e41"
      />
      <path
        d="M159.277 306.006c0 4.215 10.854 12.538 22.897 12.538s23.335-11.867 23.335-16.082-11.292.818-23.335.818-22.897-1.49-22.897 2.726z"
        fill="#fff"
      />
      <path
        d="M263.543 281.238l-31.701 6.188a3.44 3.44 0 01-2.92-.772 3.498 3.498 0 01-1.217-2.82 82.894 82.894 0 00-7.376-37.786 3.499 3.499 0 01.067-3.07 3.44 3.44 0 012.416-1.815l31.701-6.188a3.485 3.485 0 013.928 2.21 135.692 135.692 0 017.912 40.529 3.485 3.485 0 01-2.81 3.524z"
        fill="#ff6584"
      />
      <path
        d="M124.543 310.238l-31.701 6.188a3.44 3.44 0 01-2.92-.772 3.498 3.498 0 01-1.217-2.82 82.894 82.894 0 00-7.376-37.786 3.499 3.499 0 01.067-3.07 3.44 3.44 0 012.416-1.815l31.701-6.188a3.485 3.485 0 013.928 2.21 135.692 135.692 0 017.912 40.529 3.485 3.485 0 01-2.81 3.524z"
        fill="#ccc"
      />
      <path
        d="M587.653 80.332a38.017 38.017 0 01-28.545.992 37.495 37.495 0 01-24.032-33.271l-2.257 1.295 9.516 6.696a1.51 1.51 0 002.203-.897 20.078 20.078 0 019.78-12.715 26.2 26.2 0 0117.429-2.9c12.364 2.006 23.495 10.249 30.737 20.244a55.302 55.302 0 018.642 18.039 1.501 1.501 0 002.893-.798 58.45 58.45 0 00-21.528-31.502c-10.745-7.979-25.788-12.802-38.427-6.36-6.016 3.065-10.912 8.522-12.419 15.195l2.204-.897-9.516-6.695a1.51 1.51 0 00-2.257 1.295 41.07 41.07 0 0011.75 26.892A39.926 39.926 0 00571.76 86.63a40.626 40.626 0 0017.407-3.707c1.753-.803.23-3.39-1.515-2.59z"
        fill="#2f2e41"
      />
      <path
        d="M166.674 456.162l-31.701 6.188a3.44 3.44 0 01-2.92-.773 3.498 3.498 0 01-1.217-2.82 82.894 82.894 0 00-7.376-37.785 3.499 3.499 0 01.067-3.071 3.44 3.44 0 012.415-1.814l31.702-6.188a3.485 3.485 0 013.928 2.21 135.692 135.692 0 017.912 40.528 3.485 3.485 0 01-2.81 3.525z"
        fill="#6c63ff"
      />
    </svg>
  )
}

export default SvgStuckPixelRepair
